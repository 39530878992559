import React from "react";
import { Navbar } from "../components/navbar.component";
import { Footer } from "../components/footer.component";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";
import { CustomLink } from "../components/custom-link.component";
import moment from "moment";

/**
 *
 * @param {PrismicMenu} mainMenu
 * @param {PrismicMenu} footerMenu
 * @param {PrismicSocialLinks} prismicSocialLinks
 * @param {PrismicBlogPost} prismicBlogPost
 *
 * @param sendOrderForm
 * @param contacts
 * @param lang
 * @return {JSX.Element}
 * @constructor
 */
const BlogPost = ({
    data: { mainMenu, footerMenu, prismicSocialLinks, prismicBlogPost, sendOrderForm, contacts },
                  pageContext: { lang },
}) => {
    const {
        data: { title, content, image, category },
        first_publication_date
    } = prismicBlogPost;
    return (
        <>
            <Navbar menu={mainMenu}
                    social={prismicSocialLinks}
                    lang={lang}
                    sendOrderForm={sendOrderForm}
                    contacts={contacts}/>
            <section className="section-banner section-banner-blog">
                <div className="position-relative">
                    {image ? (
                        <GatsbyImage
                            className="blog-img"
                            image={image.gatsbyImageData}
                        />
                    ) : null}
                    <div className="cover-banner" />
                </div>
                <div className={"banner-content"}>
                    <div className="container padding-blog text-uppercase">
                        <div className="main-heading">
                            <p>
                                {category && category.document ? (
                                    <CustomLink
                                        className="text-primary font-weight-500"
                                        link={`/blog/${category.uid}`}
                                    >
                                        {category.document.data.name}
                                    </CustomLink>
                                ) : null}
                            </p>
                            <h1 className="blog-heading">{title.text}</h1>
                            <p>
                                {moment(first_publication_date).format(
                                    "DD.MM.YYYY"
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container padding-blog">
                <RichText render={content.raw} />
            </div>
            <Footer menu={footerMenu} socials={prismicSocialLinks} contacts={contacts} />
        </>
    );
};

export default BlogPost;

export const query = graphql`
    query BlogPostQuery($id: String!, $lang: String!) {
        prismicBlogPost(uid: { eq: $id }) {
            ...blogPost
        }
        mainMenu: prismicMenu(uid: { eq: "main-menu" }, lang: { eq: $lang }) {
            ...menu
        }
        footerMenu: prismicMenu(uid: { eq: "footer-menu" }, lang: { eq: $lang }) {
            ...menu
        }
        sendOrderForm: prismicSendOrderForm(lang: {eq: $lang}){
            ...sendOrderForm
        }
        contacts: prismicContacts{
            ...contacts
        }
        prismicSocialLinks(type: { eq: "social_links" }) {
            ...socials
        }
    }
`;
